.aside {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 25px;
  width: 115px;
  background-color: $secondaryColor;

  &__icon-panel {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 20px;
  }

  &__copyright {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 12px;
    font-weight: 500;
    color: white;
    padding-top: 60px;
  }
}
