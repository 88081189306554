.userHeading {
  &__greeting {
    font-size: 48px;
  }

  &__name {
    color: $primaryColor;
  }

  &__recap {
    font-size: 18px;
    margin: 30px 0;
  }
}

// MEDIA QUERIES
@media only screen and (max-width: 1160px) {
  .userHeading__recap {
    margin: 15px 0;
  }
}
