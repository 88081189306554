.keyData {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__metric {
    display: flex;
    height: 120px;
    width: 250px;
    border-radius: 5px;
    background-color: $tertiaryColor;
  }

  &__descriptionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
  }

  &__number {
    font-weight: 700;
    font-size: 20px;
    color: #282d30;
  }

  &__description {
    font-size: 14px;
    color: #74798c;
  }
}

// MEDIA QUERIES
@media only screen and (max-width: 1410px) {
  .keyData {
    &__wrapper {
      flex-direction: row;
    }

    &__number {
      font-size: 17px;
    }

    &__metric {
      width: 200px;
      height: 100px;
    }
  }
}
