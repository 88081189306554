// AVERAGE SESSIONS GRAPH
.sessionAverageGraph {
  &__label {
    position: absolute;
    color: white;
    z-index: 2;
    margin-top: 233px;
    margin-left: 19px;
    opacity: 0.5;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 12px;
  }

  &__tooltip {
    background-color: white;
  }

  &__value {
    color: black;
    font-size: 10px;
    padding: 10px;
  }
}

// PERFORMANCE GRAPH
.performanceGraph {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 258px;
  height: 263px;
  background-color: #282d30;
  border-radius: 5px;

  .recharts-polar-grid-angle {
    display: none;
  }
}

// DAILY ACTIVITY GRAPH
.dailyActivityGraph {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #fbfbfb;
  border-radius: 5px;
  width: 835px;
  height: 320px;

  &__label {
    font-size: 15px;
    font-weight: 500;
    position: absolute;
    margin-top: 35px;
    margin-right: 630px;
    align-self: flex-start;
    color: #282d30;
  }

  &__tooltip {
    background-color: #e60000;
    padding: 10px 5px;
  }

  &__value {
    color: white;
    font-size: 9px;
    padding: 8px 5px;
    font-weight: 500;
  }
}

// MAIN GRAPHS
.mainGraphs {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &__smaller-group {
    display: flex;
    justify-content: space-between;
  }
}

// MEDIA QUERIES
@media only screen and (max-width: 1410px) {
  .mainGraphs {
    &__wrapper {
      padding-top: 30px;
    }

    &__smaller-group {
      justify-content: space-evenly;
    }
  }

  .dailyActivityGraph {
    margin: auto;
  }
}

@media only screen and (max-width: 1160px) {
  .mainGraphs__smaller-group {
    justify-content: space-between;
  }
}
