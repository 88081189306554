// GENERAL SETTINGS

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

// VARIABLES
$primaryColor: #ff0101;
$secondaryColor: #020203;
$tertiaryColor: #fbfbfb;
